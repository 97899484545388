
import React, { Component } from 'react';
import styles from './app.module.css';
import { Button, Image, Input, message } from 'antd';
import { getUrl } from '../../services/order';
import { searchConvertObj } from '../../utils/utilHelper';

class Fill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      brand: 0,
      screenHeight: 0,
    };
  }
  componentDidMount() {
    let obj = searchConvertObj(window.location.search);
    this.setState({
      brand: obj.b,
      screenHeight: window.innerHeight,
    });
  }
  render() {
    const { code, brand, screenHeight } = this.state;
    return (
      <div className='col-sb w100 h100' style={{ height: screenHeight }}>
        <div className='col-t w100'>
          <Image src={`https://ljkj-web.oss-cn-hangzhou.aliyuncs.com/query-order/AntiFake/fw-${brand}-fill.jpg`} preview={false} className='margin-b10' />

          {/* <div className='margin-t20 fz20 bold'>即将开放,敬请期待</div> */}

          <div className={`${styles['method']} col vertical-lt w100 margin-b10`}>
            <Input value={code} onChange={this.changeInput} placeholder='请输入您的防伪码 ！' className={`${styles['input']}`} />
            <div className='row w100'>
              <Button onClick={this.toUrl} className={`${styles['btn']} margin-r10`}>查询</Button>
              <Button onClick={this.empty} className={`${styles['btn']}`}>清空</Button>
            </div>
          </div>
        </div>
        <div className={`${styles['foot']} tc w100 fz16`}>防伪验证中</div>
      </div>
    );
  }
  changeInput = (e) => {
    this.setState({
      code: e.target.value
    });
  }
  empty = () => {
    this.setState({
      code: ''
    });
  }
  toUrl = async () => {
    const { brand, code } = this.state;
    if (!code) {
      message.error('防伪码不能为空');
      return;
    }
    let redirect = window.location.origin + '/fw/code';
    let res = await getUrl(brand, code, redirect);
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    window.location.href = res.data;
    //三千金
    // window.location.href = window.location.origin + `/fw/code?state=${brand}-normal-error`;
    // window.location.href = window.location.origin + `/fw/code?state=${brand}-normal-exit`;
  }
}
export default Fill;