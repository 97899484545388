import { searchConvertObj } from '../utils/utilHelper';
let host;
let baseUrl;
let searchInfo = window.location.pathname;
let cloud = '';
if (window.location.origin.indexOf('lj2') > -1 &&
  window.location.pathname.indexOf('fw') < 0) {
  let obj = searchConvertObj(window.location.search);
  cloud = obj.c;
}

if (process.env.NODE_ENV === 'development') {
  // host = 'https://query.heartisan.vip/api';
  // host = 'https://query.xdmrz.com/api';
  // host = 'http://query.yakino.cn/api';
  // host = 'https://query.lijuyun.cn/api';
  // host = 'http://query.jinchanwarp.com/api';
  // host = 'http://query.zhiyanchina.com/api';
  // host = 'http://120.55.93.182:6644';
  // host = 'http://192.168.1.88:6644';
  // host = 'http://192.168.1.77:5500';
  host = 'https://www.lijusoft.com';
  // host = 'https://47.96.188.225:5500';
  // host = 'https://api.lijusoft.com';

  // baseUrl = 'https://ljkj-erp.oss-cn-hangzhou.aliyuncs.com/resource/';
  // baseUrl = 'https://query.jinchanwarp.vip/resource/';
  // baseUrl = 'https://query.zhiyanchina.vip/resource/';
  // baseUrl = 'https://query.heartisan.com/resource/';
  // baseUrl = 'https://query.xdmrz.com/resource/';
  // baseUrl = 'http://query.yakino.cn/resource/';
}
else {
  // if (window.location.origin.indexOf('lj2') > 0) {
  // 'https://api.lijuyun.cn/redirect/erp'
  // host = 'http://47.96.188.225:5500';
  host = 'https://www.lijusoft.com';
  baseUrl = '';
  // } else {
  //   host = `${window.location.origin}/api`;
  //   baseUrl = `${window.location.origin}/resource/`;
  // }

}

export { host, baseUrl, cloud, };