import request from '../utils/request';

export async function getOrder(id) {
  return await request(`redirect/erp/inner/${id}/price/list/v2`, 'get');
}

export async function getCourier(companyId, number) {
  return await request(`redirect/erp/inner/post/list?company=${companyId}&number=${number}`, 'get');
}

export async function getUrl(brand, code, redirect) {
  return await request(`open/weixin/unique?unique=${brand}&code=${code}&redirect=${redirect}`, 'get');
}
export async function getResult(data) {
  return await request('open/weixin/unique', 'post', data);
}
export async function getRecords(data) {
  return await request('open/weixin/unique', 'put', data);
}

